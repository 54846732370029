import React from "react"
import { Link } from "gatsby"
import * as Icon from "react-feather"

import MapImg from "../../assets/images/map.png"
import { StaticImage } from "gatsby-plugin-image"

const Footer = () => {
  const currentYear = new Date().getFullYear()
  const url = typeof window !== "undefined" ? window.location.hostname : ""

  return (
    <footer className="footer-area" style={{ background: "#000" }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-3 col-sm-6">
            <div className="single-footer-widget">
              <h5>ISO 27001:2013 Certified</h5>
              <div className="logo">
                <div style={{ display: "flex" }}>
                  <div style={{ paddingRight: "5px" }}>
                    <StaticImage
                      width={60}
                      src="../../assets/images/logo5001.png"
                      alt="logo500"
                    />
                  </div>
                  <div className="vertical-line"></div>
                  <div>
                    <StaticImage
                      width={75}
                      src="../../assets/images/logo50001.png"
                      alt="logo5000"
                    />
                  </div>
                </div>
              </div>
              <StaticImage
                // width={100}
                height={50}
                src="../../assets/images/fastestlogo1.png"
                alt="fastestLogo"
              />
              <p>
                <span style={{ fontSize: "20px", fontWeight: "600" }}>
                  Honoree for{" "}
                </span>
                <br />
                2012 | 2013 | 2014 | 2015 <br />| 2016 | 2017 | 2021 | 2022 | 2023
              </p>
            </div>
          </div>

          <div className="col-lg-2 col-sm-6">
            <div className="single-footer-widget pl-5">
              <h3>Company</h3>
              <ul className="list">
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/life-at-atyeti">Life at Atyeti</Link>
                </li>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="single-footer-widget">
              <h3>Services</h3>
              <ul className="list">
                <li>
                  <Link to="/cloud-solutions">Cloud Solutions</Link>
                </li>
                <li>
                  <Link to="/cloud-infrastructure">Cloud Infrastructure</Link>
                </li>
                <li>
                  <Link to="/application-modernization">
                    Application Modernization
                  </Link>
                </li>
                {/* <li>
                  <Link to="/total-quality-management">
                    Total Quality Management
                  </Link>
                </li> */}

                <li>
                  <Link to="/data-science">Data Science</Link>
                </li>
                <li>
                  <Link to="/google-cloud-service">Google Cloud Platform</Link>
                </li>
                <li>
                  <Link to="/enterprise-application">
                    Enterprise Applications
                  </Link>
                </li>
                <li>
                  <Link to="/business-process">
                    Business Process and Operations
                  </Link>
                </li>
                <li>
                  <Link to="/banking-and-capital">
                    Banking and Capital Markets
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="single-footer-widget">
              <h3>Address</h3>
              <ul className="footer-contact-info">
                {url === "atyeti.co.uk" ? (
                  <li>
                    <Icon.MapPin />
                    Atyeti Limited, Level 9
                    <br />
                    One Canada Square
                    <br />
                    London E14 5AA
                    <br />
                    <a href="tel:+4402071170024">+44 02071170024</a>
                  </li>
                ) : (
                  <li>
                    <Icon.MapPin />
                    Atyeti Inc
                    <br />
                    125 Village Blvd, Suite 320
                    <br />
                    Princeton NJ 08540
                    <br />
                  </li>
                )}
                <li>
                  <Icon.Mail />
                  Email:{" "}
                  <a href="mailto:atyeti-info@atyeti.com">
                    atyeti-info@atyeti.com
                  </a>
                </li>
              </ul>

              <ul className="social-links">
                <li>
                  <a
                    href="https://www.linkedin.com/company/atyeti-inc/"
                    className="linkedin"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon.Linkedin />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="copyright-area">
              <p>Copyright &copy; {currentYear} Atyeti</p>
            </div>
          </div>
        </div>
      </div>

      <img src={MapImg} className="map" alt="map" />
    </footer>
  )
}

export default Footer
